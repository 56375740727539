/** @format */

body.is-blink {
  ::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
    height: var(--blink-scrollbar-width);
    width: var(--blink-scrollbar-width);
  }

  // ::-webkit-scrollbar:hover {
  //   background-color: rgba(0, 0, 0, 0.12);
  // }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: var(--blink-scrollbar-width);
    box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
  }

  ::-webkit-scrollbar-thumb:active {
    border-radius: var(--blink-scrollbar-width);
    box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
  }

  // support hiding inactive scrollbar
  // control/inherit scrollbar color from container
  .scroll-auto::-webkit-scrollbar-thumb {
    box-shadow: none;
    background-color: inherit;
    border: 2px solid;
  }

  .scroll-auto {
    background-clip: text;
    background-color: rgba(0, 0, 0, 0);
    color: transparent;
    transition: background-color 0.5s 0.5s, color 0.5s 0.5s;

    &.scroll-visible {
      background-color: rgba(0, 0, 0, 0.54);
      color: white;
      transition: background-color 0s, color 0s;
    }
    // ensure text on child nodes is visible
    > * {
      color: #000;
      transform: translateZ(0);
    }
  }
}

body.is-dark {
  // .simplebar-track {
  //   background-color: rgba(255, 255, 255, 0.12);
  // }
  .simplebar-scrollbar::before {
    background-color: rgba(255, 255, 255, 0.54);
  }
}

@tailwind base; /** @format */
input,
textarea {
  box-sizing: content-box;
  line-height: 1.5;
}

button:focus {
  outline: unset !important;
}

code {
  background: var(--background-app-bar);
  border-radius: var(--border-radius);
  color: var(--text-color);
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 85%;
  padding: 0.2em 0.4em;
}

blockquote {
  background: theme("backgroundColor.primary.light");
  border-left: 3px solid theme("backgroundColor.primary.DEFAULT");
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  margin: 1em 0 1.5em;
  padding: 1em 1.5em;
}
blockquote > * {
  margin: 0;
}

.ml--2 {
  margin-left: -0.5rem !important;
}

h1 {
  font: 400 34px/40px var(--font);
  letter-spacing: normal;
}

h2 {
  font: 400 24px/32px var(--font);
  letter-spacing: -0.019em;
}

h3 {
  font: 500 18px/26px var(--font);
  letter-spacing: -0.014em;
}

h4 {
  font: 400 16px/28px var(--font);
  letter-spacing: -0.011em;
}

h5 {
  font: 400 15px/24px var(--font);
  letter-spacing: -0.009em;
}

h6 {
  font: 500 14px/24px var(--font);
  letter-spacing: -0.006em;
}

@tailwind components;
@tailwind utilities; /** @format */
.container {
  padding-left: var(--padding-gutter);
  padding-right: var(--padding-gutter);
}
@screen lg {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: theme("screens.lg");
    width: 100%;
  }
}

.card {
  @apply rounded shadow-8 bg-card;
}

.avatar {
  @apply h-10 w-10 object-cover rounded-full bg-hover;
}

.hidden-input {
  @apply border-2 border-solid border-transparent py-1 px-3 rounded;
}
.hidden-input:focus {
  @apply border-primary outline-none;
}
.hidden-input.empty {
  @apply border-primary;
}

.vex-list-item {
  @apply rounded h-12 px-4 cursor-pointer;
}

.vex-list-item:hover {
  @apply bg-hover;
}

.textarea-clean {
  resize: none;
}
.textarea-clean:focus {
  outline: none;
}

.bg-pattern {
  --background-fill: rgb(var(--color-hb-blue));
  --background-lines: var(--background-hover);
}

.bg-pattern-content {
  --background-fill: var(--background-base);
  --background-lines: #00000005;
}

.bg-pattern,
.bg-pattern-content {
  background: linear-gradient(135deg, var(--background-fill) 22px, var(--background-lines) 22px, var(--background-lines) 24px, transparent 24px, transparent 67px, var(--background-lines) 67px, var(--background-lines) 69px, transparent 69px), linear-gradient(225deg, var(--background-fill) 22px, var(--background-lines) 22px, var(--background-lines) 24px, transparent 24px, transparent 67px, var(--background-lines) 67px, var(--background-lines) 69px, transparent 69px) 0 64px;
  background-color: var(--background-fill);
  background-size: 64px 128px;
}

.dropdown .ellipsis-container,
.mat-list-item .ellipsis-container {
  overflow: hidden;
  flex: auto;
}
.dropdown .ellipsis-container *,
.mat-list-item .ellipsis-container * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ic-icon.chevron {
  color: var(--text-hint);
  font-size: 18px;
  height: 18px;
  width: 18px;
}

.trans-ease-out {
  transition: all 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.trans-shadow {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.vex-blogpost p {
  @apply my-3;
}
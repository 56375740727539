/** @format */

@use '@angular/material' as mat;

input,
textarea {
  box-sizing: content-box;
  line-height: 1.5;
}

button:focus {
  outline: unset !important;
}

code {
  background: var(--background-app-bar);
  border-radius: var(--border-radius);
  color: var(--text-color);
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 85%;
  padding: 0.2em 0.4em;
}

blockquote {
  background: theme('backgroundColor.primary.light');
  border-left: 3px solid theme('backgroundColor.primary.DEFAULT');
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  margin: 1em 0 1.5em;
  padding: 1em 1.5em;

  > * {
    margin: 0;
  }
}

.ml--2 {
  margin-left: -0.5rem !important;
}

// copied from var + typography scss files
h1 {
  font: 400 34px / 40px var(--font);
  letter-spacing: normal;
}

h2 {
  font: 400 24px / 32px var(--font);
  letter-spacing: -0.019em;
}

h3 {
  font: 500 18px / 26px var(--font);
  letter-spacing: -0.014em;
}

h4 {
  font: 400 16px / 28px var(--font);
  letter-spacing: -0.011em;
}

h5 {
  font: 400 15px / 24px var(--font);
  letter-spacing: -0.009em;
}

h6 {
  font: 500 14px / 24px var(--font);
  letter-spacing: -0.006em;
}

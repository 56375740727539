/** @format */

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '@angular/material/theming';

// SCSS Variables
@import 'var';

// Mixins
@import 'partials/mixins';

// Styles / CSS Variables
@import 'partials/styles/style-dark';
@import 'partials/styles/style-light';
@import 'partials/styles/style-contrast';
@import 'partials/styles/style-default';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($config);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($vex-theme);

// Markdown
@import 'partials/markdown';

// Partials
@import 'partials/chat';
@import 'partials/horizontal';
@import 'partials/vertical';
@import 'partials/print';
@import 'partials/typography';
@import 'partials/overrides';
@import 'partials/scrollbar';
@import 'partials/plugins/angular-material';
@import 'partials/plugins/apexcharts';
@import 'partials/plugins/ngx-material-timepicker';

// Layouts
@import 'partials/layouts/layout-apollo';
@import 'partials/layouts/layout-ares';
@import 'partials/layouts/layout-hermes';
@import 'partials/layouts/layout-ikaros';
@import 'partials/layouts/layout-zeus';

// Photo Viewer
@import 'viewerjs/dist/viewer.css';

// Emoji Picker
@import '@ctrl/ngx-emoji-mart/picker';

// rich text editor
// @import '~quill/dist/quill.snow.css';
// @import 'quill-emoji/dist/quill-emoji.css';

// Plus imports for other components in your app.

/* You can add global styles to this file, and also import other style files */

html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
  overflow: hidden;
}

body {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include mat.typography-level($config, body-1);
  @apply text-black leading-normal antialiased;
  font-feature-settings: 'kern';
  // prevent webkit browsers (Safari included) from messing with your font sizes
  -webkit-text-size-adjust: none;
  // controls the iOS status bar background as well
  // when apple-mobile-web-app-status-bar-style set to black-translucent
  // background-color: rgb(var(--color-accent));
}

div {
  box-sizing: border-box;
}

/** @format */

@import '~simplebar/dist/simplebar.css';
@import '~highlight.js/styles/androidstudio.css';
// Vex Core
@import './@vex/styles/core';

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --background-base: #28587e;

  // PRIMARY COLORS

  --color-hb-blue: 30, 64, 103;
  --color-hb-blue-contrast: 255, 255, 255;

  --color-hb-gray: 154, 154, 154;
  --color-hb-gray-contrast: 255, 255, 255;

  --color-hope: 165, 231, 234;
  --color-hope-contrast: var(--color-hb-blue);

  --color-empower: 57, 123, 199;
  --color-empower-contrast: 255, 255, 255;

  // SUPPORT COLORS

  --color-dignity: 44, 93, 151;
  --color-dignity-contrast: 255, 255, 255;

  --color-renew: 30, 100, 102;
  --color-renew-contrast: 255, 255, 255;

  --color-inspire: 254, 209, 109;
  --color-inspire-contrast: var(--color-hb-blue);

  --color-reach: 199, 137, 62;
  --color-reach-contrast: 255, 255, 255;

  --color-rescue: 150, 13, 103;
  --color-rescue-contrast: 255, 255, 255;

  --color-champion: 115, 24, 101;
  --color-champion-contrast: 255, 255, 255;

  --color-faith: 70, 30, 103;
  --color-faith-contrast: 255, 255, 255;

  // SYSTEM COLORS

  --color-primary: var(--color-hb-blue);
  --color-primary-contrast: 255, 255, 255;

  --color-accent: var(--color-empower);
  --color-accent-contrast: 255, 255, 255;

  --color-success: 15, 158, 103;
  --color-success-contrast: 255, 255, 255;

  --color-info: 255, 152, 0;
  --color-info-contrast: 255, 255, 255;

  // --color-warn: 182, 58, 78;
  // --color-warn-contrast: 255, 255, 255;

  // --toolbar-background: #28587e;
  // --toolbar-color: #1c3f68;
  // --toolbar-color: white;
  // --toolbar-icon-color: white;

  /**
   * sidenav
   */
  // brown
  --sidenav-background: #292421;
  --sidenav-toolbar-background: #1f1b19;
  --sidenav-item-background-active: #1f1b19;
  // blue
  --sidenav-background: #1c3f68;
  --sidenav-toolbar-background: white;
  --sidenav-color: #1c3f68;
  --sidenav-item-background-active: #11273f;
  --sidenav-item-border: 4px solid #1c3f68;
  --sidenav-item-border-color-active: #5b9bd5;

  --sidenav-item-icon-color-active: white;
  --sidenav-item-color: white;
  --sidenav-item-icon-color: white;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}

/** @format */

.mat-icon,
.mat-icon-button .mat-icon {
  font-size: var(--default-icon-size);
  height: unset;
  width: unset;
}

.caption .mat-icon {
  font-size: var(--font-caption-size);
  height: unset;
  width: unset;
}

ic-icon.app-icon {
  background-color: rgb(var(--color-primary));
  border-radius: 4px;
  color: white !important;
  margin-left: 2px;
  padding: 2px;
  display: block;
  &.disabled {
    background-color: var(--text-secondary);
  }
}

.signature {
  font-family: 'Cedarville Cursive', cursive;
  font-weight: 400;
  font-style: normal;
}

// .signature-desktop-only {
//   font-family: cursive;
//   font-style: italic;
//   letter-spacing: -1px;
// }

.text-success {
  color: rgb(var(--color-success));
}

.ic-inline > svg {
  display: inline-block;
}

.mat-progress-spinner.center {
  margin-left: auto;
  margin-right: auto;
}
.mat-button-base .mat-progress-spinner {
  margin-top: -30px;
  margin-bottom: 6px;
}
.mat-menu-item .mat-progress-spinner {
  margin-top: -36px;
  margin-bottom: 12px;
}
.mat-button-base.mat-fab .mat-progress-spinner,
.mat-button-base.mat-mini-fab .mat-progress-spinner {
  margin-top: -24px;
  margin-bottom: 0;
}
.mat-button-base.mat-icon-button .mat-progress-spinner {
  margin: -24px auto 0 auto;
}
@screen lt-sm {
  .mat-button-base.mat-button-hybrid {
    border: none;
    min-width: 40px;
    padding: 8px;
    .mat-progress-spinner {
      margin-top: -24px;
      margin-bottom: 0;
    }
  }
}

// ensure icon is always 24px tall to keep spinner placement consistent
.mat-button-base.mat-icon-button {
  ic-icon[size='20'] {
    margin: 2px 0;
    display: block;
  }
  ic-icon[size='16'] {
    margin: 4px 0;
    display: block;
  }
  ic-icon[size='14'] {
    margin: 5px 0;
    display: block;
  }
}

// buttons touch if no visible background
.mat-dialog-actions .mat-button-base.mat-button + .mat-button-base.mat-button {
  margin-left: 0;
}

.mat-sidenav {
  max-width: 85%;
}

.mat-tooltip {
  white-space: pre-line;
}

.mat-button-base mat-icon[size='24'] {
  line-height: 12px;
}

.mat-button:hover,
.mat-icon-button:hover,
.mat-stroked-button:hover,
.mat-flat-button:hover:not(.mat-primary):not(.mat-warn),
.mat-flat-button:hover[color=''],
.mat-raised-button:hover:not(.mat-primary):not(.mat-warn),
.mat-raised-button:hover[color=''] {
  background-color: var(--background-hover);
}

// FORM FIELDS

.mat-form-field:not(.mat-form-field-invalid) {
  .mat-input-element:disabled,
  .mat-form-field-label {
    color: var(--text-secondary);
  }
  .mat-form-field-outline {
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      color: var(--text-secondary);
    }
  }
}

.mat-form-field {
  ic-icon {
    color: rgba(0, 0, 0, 0.54);
  }
  &.mat-focused,
  .mat-form-field-flex:hover {
    ic-icon {
      color: rgb(var(--color-primary));
      transition: color 100ms cubic-bezier(0.25, 0.8, 0.25, 1);
    }
    mat-hint ic-icon {
      color: inherit;
    }
  }
  &.invalid {
    ic-icon,
    .mat-form-field-outline {
      color: rgb(var(--color-warn)) !important;
    }
  }
  &.warning {
    ic-icon,
    .mat-form-field-outline {
      color: rgb(var(--color-info)) !important;
    }
  }
}

.mat-form-field-appearance-outline {
  input:not(.mat-datepicker-input):read-only {
    color: var(--text-hint);
  }
}

// remove date/time-picker-indicator
.mat-form-field .mat-input-element[type='date'],
.mat-form-field .mat-input-element[type='time'] {
  // fix for layout on windows chrome
  &::after {
    content: '';
  }
}
.mat-form-field .mat-input-element[type='date']::-webkit-inner-spin-button,
.mat-form-field .mat-input-element[type='date']::-webkit-calendar-picker-indicator,
.mat-form-field .mat-input-element[type='time']::-webkit-inner-spin-button,
.mat-form-field .mat-input-element[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

// tighter spacing unless using hint
.mat-form-field:not(.allow-hint) .mat-form-field-wrapper {
  padding-bottom: 0;
}

// highlight when data changed,
// .mat-form-field.ng-dirty:not(.mat-form-field-invalid) .mat-form-field-outline > *,
.mat-checkbox.ng-dirty,
.mat-radio-group.ng-dirty,
.mat-form-field.ng-dirty,
.mat-form-field.ng-dirty .mat-select-value {
  color: rgb(var(--color-empower));
}
.mat-checkbox.ng-dirty .mat-checkbox-frame {
  border-color: rgb(var(--color-empower));
}

ic-icon,
.mat-option {
  &.invalid {
    color: rgb(var(--color-warn));
  }
  &.warning {
    color: rgb(var(--color-info));
  }
}

.mat-list-item.selected,
.mat-list-item.mat-list-single-selected-option {
  background-color: rgba(var(--color-accent), 0.1);
}

.mat-list-base .mat-list-item-disabled {
  background-color: inherit;
  ic-icon {
    color: rgba(0, 0, 0, 0.38);
  }
}

// FOCUSED MODE
body.is-focused-mode .horizontal-layout {
  --sidenav-width: 0px !important;
  --toolbar-height: 0px !important;
}

// OVERLAYS
.cdk-overlay-pane {
  max-width: 100vw !important;
  // transform: translateX(0) !important;
}
.cdk-overlay-pane > * {
  // overflow-y: auto;
  // overflow-x: hidden;
  // max-height: var(--app-height) !important;
  box-shadow: var(--elevation-z6);
}

// EXPANSION PANELS
.mat-expansion-panel {
  .mat-expansion-panel-header {
    height: 64px;
    padding: 0 16px;
    .mat-expansion-indicator::after {
      border-width: 0 0.11rem 0.11rem 0;
      margin-top: -4px;
      padding: 0.15rem;
    }
  }
  .mat-expansion-panel-body {
    padding: 0 16px 16px 16px;
  }
}

// SELECT TOO BIG ON SMALL SCREENS
@screen lt-sm {
  .mat-select-panel {
    min-width: calc(100% + 19px) !important;
  }
}

// TIME PICKER OVERLAY
ngx-material-timepicker-container {
  position: fixed;
  z-index: 1000;
}

// fix form fields not compressing
.mat-form-field {
  overflow: hidden;
}

// hides disabled options when applied to select panelClass
// remains visible if the item is the selected item
.hide-disabled-options .mat-option-disabled:not(.mat-selected) {
  display: none;
}

// datepicker fixes
.cdk-overlay-pane.mat-datepicker-popup {
  // shadow
  box-shadow: var(--elevation-z6);
  // don't scroll
  overflow: hidden;
  .mat-datepicker-content {
    overflow: hidden;
  }
}
// button highlights
.cdk-keyboard-focused .mat-calendar-body-active,
.cdk-program-focused .mat-calendar-body-active,
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover {
  > .mat-calendar-body-cell-content {
    color: rgba(var(--color-primary-contrast), 1);
  }
}

// don't show shadow on tooltips
.cdk-overlay-pane.mat-tooltip-panel > * {
  box-shadow: none;
}

.vex-page-layout {
  .vex-page-layout-header {
    z-index: 1;
    .title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.mat-simple-snack-bar-content:first-child:nth-last-child(1) {
  text-align: center;
  width: 100%;
}

// iOS sizing fixes
// html,
// body {
//   max-height: var(--app-height);
//   pointer-events: none;
//   * {
//     pointer-events: all;
//   }
// }

// remove debug style interfering with tooltips

// body.debug:before {
//   content: attr(data-debug);
//   position: absolute;
//   z-index: 999999;
//   background-color: #333;
//   color: #ccc;
//   padding: 2px 4px;
//   border-radius: 8px;
//   border: 1px solid #999;
//   overflow: scroll;
//   white-space: pre;
//   font-family: monospace;
//   max-width: 75vw;
//   max-height: 50vh;
// }

// body.debug-tl:before {
//   top: 0;
//   left: 0;
//   border-top-left-radius: 0;
// }
// body.debug-tr:before {
//   top: 0;
//   right: 0;
//   border-top-right-radius: 0;
// }
// body.debug-bl:before {
//   bottom: 0;
//   left: 0;
//   border-bottom-left-radius: 0;
// }
// body.debug-br:before {
//   bottom: 0;
//   right: 0;
//   border-bottom-right-radius: 0;
// }

// DIALOGS
.mat-dialog-container {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  > * {
    --dialog-height: min(calc(var(--app-height) * 0.95), 600px);
    --dialog-width: min(95vw, 500px);
    --dialog-action-height: 68px;
    min-height: calc(var(--dialog-height) - 48px);
    max-height: calc(var(--dialog-height) - 48px);
    min-width: calc(var(--dialog-width) - 48px);
    max-width: calc(var(--dialog-width) - 48px);
    display: flex;
    flex-direction: column;
    flex: auto;
  }
}
.mat-dialog-container .mat-dialog-title {
  background-color: rgb(var(--color-primary));
  color: rgb(var(--color-primary-contrast));
  margin: -24px -24px 0 -24px;
  padding: 16px 24px;
}
.mat-dialog-container .mat-dialog-actions {
  padding: 8px 16px;
  margin: 0 -24px -24px -24px;
}

// MOBILE DIALOG
.is-mobile .cdk-overlay-pane {
  // DISABLED DRAGGING
  &:has(> .mat-dialog-container) {
    position: static !important;
  }
  // FULLSCREEN
  .mat-dialog-container {
    border-radius: 0;
    > * {
      --dialog-height: var(--app-height);
      --dialog-width: 100vw;
    }
  }
}

.mat-menu-panel .mat-menu-title {
  background-color: rgb(var(--color-primary));
  color: rgb(var(--color-primary-contrast));
  margin: -8px 0 0 0;
  padding: 12px 24px;
}

.mat-snack-bar-container.warn,
.mat-snack-bar-container.warn .mat-button {
  background-color: rgba(var(--color-warn), 1);
  color: rgba(var(--color-warn-contrast), 1);
}

.mat-expansion-indicator::after {
  border-color: var(--text-hint);
}

ic-icon:not(.ic-inline) > svg,
.iconify:not(.ic-inline) > svg {
  margin: 0 auto;
  vertical-align: middle;
}

.vex-scrollblock {
  position: fixed;
  width: 100%;
}

// photo preview
.viewer-toolbar > ul > li {
  height: 32px;
  width: 32px;
  margin-left: 8px;
  margin-right: 8px;
}
.viewer-toolbar > ul > li::before {
  margin: 6px;
}
.viewer-toolbar > ul > .viewer-large {
  height: 42px;
  margin-bottom: -6px;
  margin-top: -6px;
  width: 42px;
}
.viewer-toolbar > ul > .viewer-large::before {
  margin: 11px;
}
.viewer-toolbar .viewer-download {
  color: #fff;
  font-family: FontAwesome, serif;
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-align: center;
}
.viewer-toolbar .viewer-download::before {
  content: 'Download';
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAD1BMVEUAAAD///////////////+PQt5oAAAABHRSTlMAd3+ApzL+VgAAAAFiS0dEBI9o2VEAAAA4SURBVBjTY2AgHrC4uDhQi8PoAgYKEJ4IiO2ELKUA0yOCkABLKSDcI+KE5DhmA6I8AAIOmBySAQBjuw7hAT3jRQAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: 20px;
  color: transparent;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  width: 20px;
}

// emoji picker
emoji-mart {
  .emoji-mart-search-icon {
    top: 6px;
  }
  .emoji-mart-category .emoji-mart-emoji:hover:before {
    top: 3px;
    left: 4px;
  }
}

// SNACKBAR

.mat-snack-bar-container.prepend {
  > * {
    margin-left: 32px;
  }
  .mat-simple-snackbar {
    min-height: 24px;
  }
  &:before {
    height: 24px;
    overflow: hidden;
    position: absolute;
    width: 24px;
  }
  &.chat-icon:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E %3Cpath fill='white' fill-opacity='0.7' d='M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2z'/%3E %3C/svg%3E");
  }
  &.security-icon:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E %3Cpath fill='white' fill-opacity='0.7' d='M12 1L3 5v6c0 5.55 3.84 10.74 9 12c5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z'/%3E %3C/svg%3E");
  }
}

// flip
.flip-x {
  transform: scaleX(-1);
}
.flip-y {
  transform: scaleY(-1);
}

// grid
.grid-cols-min-auto {
  grid-template-columns: min-content auto;
}
.grid-cols-auto-min {
  grid-template-columns: auto min-content;
}
.grid-cols-min-auto-min {
  grid-template-columns: min-content auto min-content;
}

// DISABLE SELECT
.mat-dialog-title,
.mat-tab-header,
.caption.text-secondary {
  user-select: none;
}

.mat-select-panel.multi-line-options .mat-option {
  height: auto;
  min-height: 3em;
  .mat-option-text {
    @apply body-1;
    padding: 8px 0 8px 8px;
    white-space: normal;
  }
}

// hide sibling elements when clear button visible
.mat-select-arrow-wrapper:has(~ vex-clear-button:not(.hidden)),
.mat-form-field-suffix > *:has(~ vex-clear-button:not(.hidden)) {
  opacity: 0;
}
vex-clear-button:has(button) {
  background-color: transparent !important;
}

// HORIZONTAL STEPPER
@media (max-width: 599px) {
  .mat-stepper-horizontal {
    // horizontal header
    .mat-horizontal-stepper-header-container {
      .mat-step-label:not(.mat-step-label-selected) {
        display: none;
      }
      .mat-stepper-horizontal-line {
        min-width: 0;
      }
    }
  }
}

/** @format */

@media print {
  html,
  body,
  .page-container {
    --sidenav-width: 0;
    --toolbar-height: 0;
    --footer-height: 0;
    height: auto !important;
    overflow: initial !important;
    position: relative !important;
  }

  .config-panel-toggle,
  .rightnav,
  .sidenav,
  .vex-footer,
  .vex-toolbar,
  vex-action-buttons {
    display: none !important;
  }

  .content {
    margin-top: 0 !important;
  }

  .mat-drawer-container {
    overflow: visible !important;
  }

  .mat-drawer-side {
    border-right: none !important;
  }

  .sidenav-content,
  .mat-drawer-content {
    position: relative !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    @apply bg-card;
  }
}

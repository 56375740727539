/** @format */

.horizontal-layout {
  --navigation-height: 0px;
  --toolbar-width: 100%;

  &.is-desktop {
    --toolbar-width: calc(100% - var(--sidenav-width));

    &.sidenav-collapsed {
      --toolbar-width: calc(100% - var(--sidenav-collapsed-width));
    }
  }

  .sidenav-container {
    /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
       causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
    flex: 1;
  }

  &.has-fixed-footer {
    &.scroll-disabled .content {
      height: calc(100% - var(--toolbar-height) - var(--footer-height));
    }
  }
}

body:not([dir='rtl']):not(.is-focused-mode) {
  .horizontal-layout.is-desktop {
    &.sidenav-collapsed .sidenav-content {
      margin-left: var(--sidenav-collapsed-width) !important;
      margin-right: 0 !important;
    }

    &:not(.sidenav-collapsed) .sidenav-content {
      margin-left: var(--sidenav-width) !important;
      margin-right: 0 !important;
    }
  }
}

[dir='rtl']:not(.is-focused-mode) {
  .horizontal-layout.is-desktop {
    &.sidenav-collapsed .sidenav-content {
      margin-right: var(--sidenav-collapsed-width) !important;
      margin-left: 0 !important;
    }

    &:not(.sidenav-collapsed) .sidenav-content {
      margin-right: var(--sidenav-width) !important;
      margin-left: 0 !important;
    }
  }
}

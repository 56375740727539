/** @format */

.timepicker {
  /* Timepicker common */
  --body-background-color: #fff;
  --primary-font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple ColorDef Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto ColorDef Emoji';
  --button-color: rgb(var(--color-primary));

  /* Timepicker dial */
  --dial-active-color: rgb(var(--color-accent-contrast));
  --dial-inactive-color: rgba(var(--color-accent-contrast), 0.5);
  --dial-background-color: rgb(var(--color-accent));
  --dial-editable-active-color: rgb(var(--color-accent));
  --dial-editable-background-color: rgb(var(--color-accent-contrast));

  /* Timepicker clock face */
  --clock-face-time-active-color: rgb(var(--color-accent-contrast));
  --clock-face-time-inactive-color: #6c6c6c;
  --clock-face-inner-time-inactive-color: #929292;
  --clock-face-time-disabled-color: #c5c5c5;
  --clock-face-background-color: #f0f0f0;
  --clock-hand-color: rgb(var(--color-accent));

  /* make a bit more material */
  overflow: hidden;
  border-radius: 4px !important;

  .timepicker-button {
    border-radius: 4px !important;
  }
}

.ngx-material-timepicker-toggle {
  padding: 8px !important;
}

/** @format */

@import './var.scss';

.container {
  padding-left: var(--padding-gutter);
  padding-right: var(--padding-gutter);

  @screen lg {
    margin-left: auto;
    margin-right: auto;
    max-width: theme('screens.lg');
    width: 100%;
  }
}

.card {
  @apply rounded shadow-8 bg-card;
}

.avatar {
  @apply h-10 w-10 object-cover rounded-full bg-hover;
}

.hidden-input {
  @apply border-2 border-solid border-transparent py-1 px-3 rounded;

  &:focus {
    @apply border-primary outline-none;
  }

  &.empty {
    @apply border-primary;
  }
}

.vex-list-item {
  @apply rounded h-12 px-4 cursor-pointer;
}

.vex-list-item:hover {
  @apply bg-hover;
}

.textarea-clean {
  resize: none;

  &:focus {
    outline: none;
  }
}

// BACKGROUNDS
// UNAUTHENTICATED
.bg-pattern {
  --background-fill: rgb(var(--color-hb-blue));
  --background-lines: var(--background-hover);
}

.bg-pattern-content {
  --background-fill: var(--background-base);
  --background-lines: #00000005;
}

.bg-pattern,
.bg-pattern-content {
  background: linear-gradient(
      135deg,
      var(--background-fill) 22px,
      var(--background-lines) 22px,
      var(--background-lines) 24px,
      transparent 24px,
      transparent 67px,
      var(--background-lines) 67px,
      var(--background-lines) 69px,
      transparent 69px
    ),
    linear-gradient(
        225deg,
        var(--background-fill) 22px,
        var(--background-lines) 22px,
        var(--background-lines) 24px,
        transparent 24px,
        transparent 67px,
        var(--background-lines) 67px,
        var(--background-lines) 69px,
        transparent 69px
      )
      0 64px;
  background-color: var(--background-fill);
  background-size: 64px 128px;
}

// .bg-pattern-content > * {
//   background-image: linear-gradient(
//     to bottom,
//     var(--background-fill) 0 25vh,
//     transparent
//   );
//   background-attachment: fixed;
// }

// list-item overflow handling
.dropdown,
.mat-list-item {
  .ellipsis-container {
    overflow: hidden;
    flex: auto;
    * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

ic-icon.chevron {
  color: var(--text-hint);
  font-size: 18px;
  height: 18px;
  width: 18px;
}

// Transitions

.trans-ease-out {
  transition: $swift-ease-out;
}

.trans-shadow {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.vex-blogpost {
  p {
    @apply my-3;
  }
}

/** @format */

@import '../../var';

:root .vex-style-contrast {
  // save original values
  --org-color: var(--color-primary);
  --org-contrast: var(--color-primary-contrast);
  --org-hover: var(--org-hover);

  // update for static contrast
  --text-secondary: rgba(0, 0, 0, 0.6);
  --text-hint: rgba(0, 0, 0, 0.6);

  .opacity-50 {
    opacity: 0.9;
  }

  // update for hover contrast
  .hover\:bg-hover:hover,
  .hover\:bg-hover.bg-hover,
  // button
  .mat-button:hover,
  .mat-icon-button:hover,
  .mat-stroked-button:hover,
  .mat-flat-button:hover:not(.mat-primary):not(.mat-warn),
  .mat-flat-button:hover[color=""],
  .mat-raised-button:hover:not(.mat-primary):not(.mat-warn),
  .mat-raised-button:hover[color=""],
  // listview
  .mat-action-list .mat-list-item:focus,
  .mat-action-list .mat-list-item:hover,
  .mat-list-option:focus,
  .mat-list-option:hover,
  .mat-nav-list .mat-list-item:focus,
  .mat-nav-list .mat-list-item:hover,
  .mat-nav-list .mat-list-item.selected,
  // select
  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
  .mat-option:hover:not(.mat-option-disabled),
  .mat-option:focus:not(.mat-option-disabled) .mat-option.mat-active,
  // expansion panel
  .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
  .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
  // stepper
  .mat-stepper-vertical .mat-step .mat-step-header:hover,
  .mat-stepper-vertical .mat-step .mat-step-header[aria-selected=true],
  // tree
  .mat-tree-node.leaf-node.expanded .folder-row,
  .mat-tree-node.leaf-node:not(.selected) .leaf-item:hover,
  .mat-tree-node.leaf-node:not(.selected) .folder-item:hover,
  .mat-tree-node.folder-node.expanded .folder-row,
  .mat-tree-node.folder-node:not(.selected) .leaf-item:hover,
  .mat-tree-node.folder-node:not(.selected) .folder-item:hover,
  .mat-tree-node.leaf-node.selected .leaf-item,
  .mat-tree-node.leaf-node.selected .folder-item,
  .mat-tree-node.folder-node.selected .leaf-item,
  .mat-tree-node.folder-node.selected .folder-item,
  // custom
  a[mat-button]:hover,
  button.selected,
  button.mat-menu-item:hover,
  .notification:hover {
    --background-hover: rgba(0, 0, 0, 0.6);
    --color-primary: var(--org-contrast);
    --color-primary-contrast: var(--org-color);
    --color-accent: var(--org-contrast);
    --color-warn: var(--org-contrast);
    // --color-accent-contrast: var();
    --sidenav-color: rgb(var(--org-contrast));
    --text-color-light: var(--background-hover);
    --text-color: rgb(var(--org-contrast));
    --text-hint: rgb(var(--org-contrast));
    --text-primary: rgb(var(--org-contrast));
    --text-secondary: rgb(var(--org-contrast));
    --text-warn: rgb(var(--org-contrast));
    --toolbar-icon-color: rgb(var(--org-contrast));
    color: rgb(var(--org-contrast));
    background: var(--background-hover);

    .mat-step-icon {
      color: rgb(var(--org-contrast));
      background: var(--background-hover);
    }
    .mat-radio-button.mat-accent .mat-radio-outer-circle,
    //&.mat-list-item,
    .mat-list-base .mat-list-item,
    .mat-expansion-panel-header-title,
    .text-green,
    .text-gray,
    .text-orange {
      color: rgb(var(--org-contrast));
      border-color: rgb(var(--org-contrast));
    }
    .mat-radio-button.mat-accent {
      --color-accent: var(--org-contrast);
    }
  }
  // calendar
  .cal-month-view,
  .cal-week-view,
  .cal-agenda-view {
    --background-hover: rgba(0, 0, 0, 0.6);
  }
  .cal-month-view .cal-day-number,
  .cal-month-view .mat-button.cal-event-past,
  .cal-month-view .cal-day-event.cal-event-past,
  .cal-week-view .cal-event.cal-event-past,
  .cal-agenda-view .cal-agenda-date.cal-past > *,
  .cal-agenda-view .cal-agenda-event.cal-past > *,
  .cal-month-view .cal-day-cell.cal-past .cal-day-number {
    opacity: 1;
  }
  .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
  .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom-color: rgb(var(--foreground-divider));
  }
  // chip
  .mat-chip.mat-standard-chip {
    --text-secondary: rgb(var(--org-contrast));
    background-color: rgba(0, 0, 0, 0.6);
    color: rgb(var(--org-contrast));
  }
}

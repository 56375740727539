/** @format */

.dot-typing {
  background-color: transparent;
  animation: dotTyping 1s infinite linear;
  margin-left: -6px;
  margin-right: 24px;
}
.dot-typing,
.dot-typing::before,
.dot-typing::after {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 3px;
}

.dot-typing::before,
.dot-typing::after {
  content: '';
  display: block;
}
.dot-typing::before {
  left: 9px;
  animation: dotTyping 1s infinite linear;
  animation-delay: 0.2s;
}

.dot-typing::after {
  left: 18px;
  top: -6px;
  animation: dotTyping 1s infinite linear;
  animation-delay: 0.4s;
}

@keyframes dotTyping {
  0% {
    box-shadow: 6px 0 0 0 rgb(var(--color-accent));
  }
  20% {
    box-shadow: 6px -8px 0 0 rgb(var(--color-accent));
  }
  40% {
    box-shadow: 6px 4px 0 0 rgb(var(--color-accent));
  }
  60% {
    box-shadow: 6px 0 0 0 rgb(var(--color-accent));
  }
  100% {
    box-shadow: 6px 0 0 0 rgb(var(--color-accent));
  }
}
